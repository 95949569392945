const smSwiper = new Swiper('.swiper-wrapper-sm', {
  // Optional parameters
  loop: true,
  speed: 4000,
  spaceBetween: 0,
  centeredSlides: true,
  effect: 'fade',
  autoplay: {
    delay: 5000,
    stopOnLast: false,
    disableOnInteraction: false,
  }
});

const pcSwiper = new Swiper('.swiper-wrapper-pc', {
  // Optional parameters
  loop: true,
  speed: 4000,
  spaceBetween: 0,
  centeredSlides: true,
  effect: 'fade',
  autoplay: {
    delay: 5000,
    stopOnLast: false,
    disableOnInteraction: false,
  }
});
